import { useEffect } from "react";
import { compose } from "../../../../../../../../../../components/compose/WlyCompose";
import { useLagoonQuery } from "../../../../../../../../../../components/hooks/query/useLagoonQuery";
import Feednack from "../../../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../../../components/layout/feedback/loading";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import { IDB_GET_ERROR } from "../../../../../../../../../../services/idbService";
import {
  useWidgetCacheActions,
  type WidgetCacheProps,
} from "../../../../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../../../../domain";
import type { kpiTheme } from "../../../domain";
import { getSparklineQuery, type IWidgetSparkline } from "../domain";
import { TableSparklineRenderer } from "./TableSparklineRenderer";
import { useTableSparklineConfig } from "./useTableSparklineConfig";

interface ITableSparklineProps {
  theme: kpiTheme;
  object: IObject;
  record: IRecord;
  locale: UserLocale;
  conf: IWidgetSparkline;
  widgetCacheProps: WidgetCacheProps;
}

type Props = ITableSparklineProps & InjectedOrgProps;

function TableSparkline(props: Props) {
  const { theme, object, record, conf, locale, org, widgetCacheProps } = props;

  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);

  const query = getSparklineQuery({
    conf: conf,
    object: object,
    record: record,
  });

  const { data, loading, error } = useLagoonQuery(query, {
    objectType: "OBJECT",
    objectId: object.id,
    org: org,
    enabled: !!query,
  });

  useEffect(() => {
    if (data || error) {
      setAsFinished(!!error);
    }
  }, [data, error, setAsFinished]);

  const config = useTableSparklineConfig({ conf, object, query });

  if (!query || !config) {
    return <></>;
  }

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    if (error.cause === IDB_GET_ERROR) {
      return <Feednack>You need to be online to view this content</Feednack>;
    }

    return (
      <Feednack>
        <>{error.message}</>
      </Feednack>
    );
  }

  return (
    <TableSparklineRenderer
      config={config}
      theme={theme}
      locale={locale}
      data={data ?? []}
    />
  );
}

export default compose<Props, ITableSparklineProps>(WithOrg)(TableSparkline);
