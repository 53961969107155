import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Switch, Tooltip, Typography } from "antd";
import { useState } from "react";
import type { AvailableDimension } from "../../../../../../../components/measures/filter-item/FilterItem";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import type { AvailableColumn } from "../../../../object/domain";
import type { IConfiguration } from "../related-lists/domain";

import classNames from "classnames";
import type { IObject } from "../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../interfaces/org";
import { AdditionalSider } from "./AdditionalSider";
import { SingleColumnSettingsEditor } from "./ColumnSettingsEditor";
import { FilterSelector } from "./FilterSelector";
import "./genericEditorStyle.scss";
import { GroupSelector } from "./GroupSelector";
import { MoveActions } from "./MoveActions";
import { SortSelector } from "./SortSelector";

type ConfigurationSelectorProps = {
  availableColumns: AvailableColumn[];
  availableDimensionsToGroup: AvailableDimension[];
  availableDimensionsToFilter: AvailableDimension[];
  label: string;
  name: string[];
  org: IOrg;
  object: IObject;
};

export const ConfigurationSelector = ({
  availableColumns,
  availableDimensionsToGroup,
  availableDimensionsToFilter,
  label,
  name,
  org,
  object,
}: ConfigurationSelectorProps) => {
  // Because Antd Form don't allow conditional rendering on Form.Item...
  const [fieldNameBeingEdited, setFieldNameBeingEdited] = useState<
    number | undefined
  >(undefined);

  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Flex vertical className="configuration-selector">
              <Typography.Text className="label">{label}</Typography.Text>

              <Form.List name={name}>
                {(fields, { add, remove, move }) => (
                  <Flex vertical gap="small">
                    {fields.map((field, index) => {
                      const moveDown = () => {
                        index < fields.length - 1 && move(index, index + 1);
                      };
                      const moveUp = () => {
                        index > 0 && move(index, index - 1);
                      };

                      return (
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          key={`configuration-${field.name}-${field.key}`}
                          name={[field.name]}
                        >
                          <ConfigurationElement
                            fieldName={field.name}
                            availableColumns={availableColumns}
                            availableDimensionsToGroup={
                              availableDimensionsToGroup
                            }
                            availableDimensionsToFilter={
                              availableDimensionsToFilter
                            }
                            org={org}
                            object={object}
                            remove={() => remove(field.name)}
                            fieldNameBeingEdited={fieldNameBeingEdited}
                            setFieldNameBeingEdited={setFieldNameBeingEdited}
                            moveDown={moveDown}
                            moveUp={moveUp}
                          />
                        </Form.Item>
                      );
                    })}

                    <Button
                      size="small"
                      block
                      onClick={() => add({ name: "New configuration" })}
                    >
                      Add
                    </Button>
                  </Flex>
                )}
              </Form.List>
            </Flex>
          ),
          flex: 1,
        },
      ]}
    />
  );
};

type ConfigurationElementProps = {
  value?: IConfiguration;
  fieldName: number;
  availableColumns: AvailableColumn[];
  availableDimensionsToGroup: AvailableDimension[];
  availableDimensionsToFilter: AvailableDimension[];
  org: IOrg;
  object: IObject;
  remove: () => void;
  fieldNameBeingEdited: number | undefined;
  setFieldNameBeingEdited: (value: number | undefined) => void;
  moveDown: () => void;
  moveUp: () => void;
};
const ConfigurationElement = ({
  value,
  fieldName,
  availableColumns,
  availableDimensionsToGroup,
  availableDimensionsToFilter,
  org,
  object,
  remove,
  fieldNameBeingEdited,
  setFieldNameBeingEdited,
  moveDown,
  moveUp,
}: ConfigurationElementProps) => {
  const open = fieldNameBeingEdited === fieldName;
  const { name, isDefault } = value ?? {};

  return (
    <>
      <AdditionalSider
        open={open}
        title="Edit configuration"
        onClose={() => setFieldNameBeingEdited(undefined)}
      >
        <ConfigurationElementEditor
          fieldName={fieldName}
          availableColumns={availableColumns}
          availableDimensionsToGroup={availableDimensionsToGroup}
          availableDimensionsToFilter={availableDimensionsToFilter}
          org={org}
          object={object}
        />
      </AdditionalSider>

      <div
        className={classNames("configuration-element", isDefault && "default")}
      >
        <Flex gap="small" justify="space-between">
          <Flex gap="small" align="center">
            <MoveActions moveUp={moveUp} moveDown={moveDown} />

            <Typography.Text strong ellipsis>
              {name}
            </Typography.Text>
          </Flex>

          <Flex gap="small">
            <Tooltip title="Edit">
              <Button
                size="small"
                type="text"
                onClick={() =>
                  setFieldNameBeingEdited(open ? undefined : fieldName)
                }
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title="Remove">
              <Button
                size="small"
                type="text"
                onClick={remove}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

type ConfigurationElementEditorProps = {
  fieldName: number;
  availableColumns: AvailableColumn[];
  availableDimensionsToGroup: AvailableDimension[];
  availableDimensionsToFilter: AvailableDimension[];
  org: IOrg;
  object: IObject;
};
const ConfigurationElementEditor = ({
  fieldName,
  availableColumns,
  availableDimensionsToGroup,
  availableDimensionsToFilter,
  org,
  object,
}: ConfigurationElementEditorProps) => {
  const formInstance = Form.useFormInstance();

  return (
    <Flex vertical className="configuration-element-editor">
      <Form.Item label="Name" name={[fieldName, "name"]}>
        <Input size="small" />
      </Form.Item>

      <SortSelector
        availableColumns={availableColumns}
        label="Sort by"
        name={[fieldName, "sortBy"]}
      />

      <Flex vertical>
        <GroupSelector
          availableDimensions={availableDimensionsToGroup}
          label="Group by"
          name={[fieldName, "groupBy"]}
        />

        <SingleColumnSettingsEditor
          name={[fieldName, "groupByColumnSettings"]}
          label="Group by column settings"
          formInstance={formInstance}
        />
      </Flex>

      <FilterSelector
        availableDimensions={availableDimensionsToFilter}
        label="Filter by"
        name={[fieldName, "filters"]}
        org={org}
        object={object}
      />

      <Flex justify="space-between" align="center">
        <Typography.Text>Default :</Typography.Text>
        <Form.Item
          valuePropName="checked"
          name={[fieldName, "isDefault"]}
          noStyle
        >
          <Switch size="small" />
        </Form.Item>
      </Flex>
    </Flex>
  );
};
