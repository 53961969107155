import type { Filter } from "@cubejs-client/core";
import type { ColumnSizeState } from "ag-grid-community";
import type { AgGridReact } from "ag-grid-react";
import { isEqual } from "lodash";
import * as React from "react";
import { usePrevious } from "react-use";
import ObjectTable from "../../../../../../../../components/ag-grid/object-table/ObjectTable";
import type {
  ColumnSettings,
  ColumnsSettings,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import { buildQuery } from "../../../../../../../../components/ag-grid/object-table/domain";
import { useCubeJSDatasource } from "../../../../../../../../components/ag-grid/object-table/useCubeJSDatasource";
import { useTableQuery } from "../../../../../../../../components/ag-grid/object-table/useTableQuery";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { useIsOnline } from "../../../../../../../../components/hooks/useIsOnline";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import { IDB_GET_ERROR } from "../../../../../../../../services/idbService";
import type { WidgetCacheProps } from "../../../../../../../../store/widgetCacheStore";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import type { AvailableColumn } from "../../../../../object/domain";
import { getDimensionPartFromTimeDimension } from "../../../../../object/viewer/domain";

interface IRelatedListQueryProps {
  object: IObject;
  columns: string[];
  filters?: Filter[];
  sortBy?: [string, "asc" | "desc"][];
  groupBy?: Array<{ id: string; agg?: string | undefined }>;
  availableColumns: AvailableColumn[];
  pageSize: number;
  hidePagination?: boolean;
  showRowNumber?: boolean;
  columnsSettings?: ColumnsSettings;
  groupByColumnSettings?: ColumnSettings;
  getRowCount?: (f: Filter[]) => Promise<void>;
  widgetCacheProps: WidgetCacheProps;
}

type Props = IRelatedListQueryProps & InjectedOrgProps;

function RelatedListQuery(props: Props) {
  const {
    org,
    columns,
    object,
    filters,
    sortBy,
    groupBy,
    pageSize,
    availableColumns,
    hidePagination,
    showRowNumber,
    columnsSettings,
    groupByColumnSettings,
    getRowCount,
    widgetCacheProps,
  } = props;

  const gridRef = React.useRef<AgGridReact>(null);
  const isOnline = useIsOnline();
  const [missingOfflineValue, setMissingOfflineValue] = React.useState(false);
  const prevFilters = usePrevious(filters);
  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);

  React.useEffect(() => {
    if (!isEqual(prevFilters, filters)) {
      getRowCount?.(filters ?? []);
    }
  }, [JSON.stringify(filters), JSON.stringify(prevFilters)]);

  const columnSizingModel: ColumnSizeState[] = React.useMemo(
    () =>
      Object.entries(columnsSettings ?? {}).map(
        ([key, { initialSizeType, initialSizeWidth }]) => {
          const useDefault = initialSizeType === "default";
          const useFixedWidth =
            initialSizeType === "fixedWidth" &&
            typeof initialSizeWidth === "number";

          return {
            colId: key,
            flex: useFixedWidth || useDefault ? undefined : 1,
            width: useFixedWidth ? initialSizeWidth : undefined,
          };
        }
      ),
    [columnsSettings]
  );

  const defaultQuery: TableQuery = React.useMemo(
    () => ({
      columnVisibility: {
        visibleColumnIds: columns,
      },
      queryBuilderItems: {},
      filters: filters ?? [],
      groups: (groupBy ?? []).map((cv) => {
        if (cv.agg) {
          return {
            groupedDimension: getDimensionPartFromTimeDimension(cv.id),
            groupedField: cv.id + "." + cv.agg,
          };
        } else {
          return {
            groupedDimension: cv.id,
            groupedField: cv.id,
          };
        }
      }),
      order: sortBy?.length ? sortBy : [],
      columnOrder: {
        orderedColIds: columns,
      },
      columnSizing: { columnSizingModel },
    }),
    [columns, columnSizingModel, filters, groupBy, sortBy]
  );

  const previousDefaultQuery = usePrevious(defaultQuery);

  const [tableQuery, setTableQuery] = useTableQuery({
    defaultQuery: defaultQuery,
    initialQuery: defaultQuery,
    getInitialQuery: () => defaultQuery,
  });

  React.useEffect(() => {
    gridRef?.current?.api?.refreshServerSide?.({ purge: true });
  }, [
    tableQuery.presetFilters,
    tableQuery.filters,
    tableQuery.groups,
    tableQuery.order,
    tableQuery.columnVisibility?.visibleColumnIds,
  ]);

  React.useEffect(() => {
    if (!isEqual(previousDefaultQuery, defaultQuery)) {
      setTableQuery({
        action: "setTableQuery",
        tableQuery: defaultQuery,
      });
      gridRef?.current?.api?.setRowGroupColumns(
        defaultQuery.groups.map((v) => v.groupedDimension)
      );
    }
  }, [defaultQuery, previousDefaultQuery, setTableQuery]);

  const cubeJSDataSource = useCubeJSDatasource({
    buildQuery,
    object,
    org,
    availableColumns,
    onSuccess: (results, query) => {
      setAsFinished();
    },
    onError: (error) => {
      if (typeof error !== "string" && error.cause === IDB_GET_ERROR) {
        setMissingOfflineValue(true);
      } else {
        setAsFinished(true);
        console.error(error);
      }
    },
  });

  // React.useEffect(() => {
  //   console.log({ tqGroups: tableQuery.groups, groupBy });
  // }, [JSON.stringify(tableQuery.groups), JSON.stringify(groupBy)]);

  /* TODO: Dirty fix, we need to find a better way to handle this (It is surely a race condition) */
  React.useEffect(() => {
    setTimeout(() => {
      gridRef?.current?.api?.setRowGroupColumns(
        tableQuery.groups.map((v) => v.groupedDimension)
      );
    }, 250);
  }, [JSON.stringify(tableQuery.groups)]);

  if (!isOnline && missingOfflineValue) {
    return <Feednack>You need to be online to view this content</Feednack>;
  }

  return (
    <div
      style={{
        margin: -12,
        borderRadius: 8,
        overflow: "hidden",
        paddingTop: 1,
      }}
    >
      <ObjectTable
        displayType="relatedList"
        availableColumns={availableColumns}
        cubeJSDataSource={cubeJSDataSource}
        gridRef={gridRef}
        object={object}
        columnsSettings={columnsSettings}
        groupByColumnSettings={groupByColumnSettings}
        tableQuery={tableQuery}
        setTableQuery={setTableQuery}
        showRowNumbers={showRowNumber}
        pagination={{
          enabled: !hidePagination,
          pageSize: pageSize,
        }}
      />
    </div>
  );
}

export default compose<Props, IRelatedListQueryProps>(WithOrg)(
  RelatedListQuery
);
