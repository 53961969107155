import { Form, Modal, Radio, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IReport } from "../../../../../../../../interfaces/reports";
import type { ILinkItem } from "../domain";

export interface ILinkAdditionModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (i: ILinkItem) => void;
  allObjects: IObject[];
  allReports: IReport[];
}

export function LinkAdditionModal(props: ILinkAdditionModalProps) {
  const { open, onClose, onSubmit, allReports, allObjects } = props;
  const [form] = useForm();
  return (
    <Modal
      open={open}
      onOk={() => form.submit()}
      onCancel={onClose}
      destroyOnClose={true}
      title={"Add link in collection"}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(v) => {
          if (v.object_view_id) {
            onSubmit({
              id: `object-view::${v.object_view_id}|object::${v.object_id}`,
            });
          } else if (v.object_id) {
            onSubmit({ id: `object::${v.object_id}` });
          } else if (v.report_id) {
            onSubmit({ id: `report::${v.report_id}` });
          }
          onClose();
        }}
      >
        <Form.Item label={"Type"} name={["type"]}>
          <Radio.Group
            style={{ width: "100%" }}
            options={[
              {
                label: "Object",
                value: "object",
              },
              {
                label: "Reports",
                value: "report",
              },
            ]}
            defaultValue="object"
            optionType="button"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => {
            const type = form.getFieldValue(["type"]);
            if (type === "report") {
              return (
                <Form.Item label="Report" name={["report_id"]}>
                  <Select style={{ width: "100%" }}>
                    {allReports.map((r) => {
                      return (
                        <Select.Option key={r.id} value={r.id}>
                          {r.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <>
                  <Form.Item label="Object" name={["object_id"]}>
                    <Select style={{ width: "100%" }}>
                      {allObjects.map((r) => {
                        return (
                          <Select.Option key={r.id} value={r.id}>
                            {r.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => {
                      const val = form.getFieldValue(["object_id"]);
                      if (!val) {
                        return;
                      }
                      const found = allObjects.find((o) => o.id === val);
                      if (!found || found.views.length === 0) {
                        return;
                      }
                      return (
                        <Form.Item label={"View"} name={["object_view_id"]}>
                          <Select style={{ width: "100%" }}>
                            <Select.Option>Default view</Select.Option>
                            {found.views.map((v) => {
                              return (
                                <Select.Option key={v.id} value={v.id}>
                                  {v.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              );
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}
