import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  Flex,
  Form,
  Input,
  Space,
  Typography,
  type FormInstance,
} from "antd";
import { useState } from "react";
import { grey } from "../../../../../../../../utils/colorPalette";
import { MoveActions } from "../../generic-editor-parts/MoveActions";

type LinkCollapseProps = {
  collapseKey: string;
  baseName: (string | number)[];
  displayName: string;
  displayNameType?: "danger";
  formInstance: FormInstance;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
};

export const LinkCollapse = ({
  collapseKey,
  displayName,
  baseName,
  formInstance,
  moveDown,
  moveUp,
  remove,
}: LinkCollapseProps) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <Collapse
      activeKey={showForm ? collapseKey : undefined}
      bordered={false}
      size="small"
      items={[
        {
          key: collapseKey,
          label: (
            <Flex gap="small" justify="space-between" align="center">
              <Flex gap="small" align="center">
                <MoveActions moveDown={moveDown} moveUp={moveUp} />
                <Typography.Text strong>{displayName}</Typography.Text>
              </Flex>

              <Button
                size="small"
                type="text"
                icon={<EditOutlined />}
                onClick={() => setShowForm((prev) => !prev)}
              />
            </Flex>
          ),
          children: (
            <>
              <Space direction="vertical" style={{ width: "100%" }} size={12}>
                <div>
                  <Typography.Text strong>Override name</Typography.Text>
                  <br />
                  <Form.Item name={[...baseName, "overrideName"]} noStyle>
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div>
                  <Button
                    type="dashed"
                    block
                    size="small"
                    onClick={() => remove()}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Space>
              <Form.Item name={[...baseName, "id"]} hidden noStyle>
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </>
          ),
          showArrow: false,
          style: {
            background: grey[3],
            borderRadius: 6,
          },
        },
      ]}
      style={{
        background: grey[3],
        borderRadius: 6,
        overflow: "hidden",
      }}
    />
  );
};
