import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Flex, Form, Input, Switch, Tooltip, Typography } from "antd";
import { useState } from "react";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import type { AvailableColumn } from "../../../../object/domain";
import type { IColumnGroup } from "../related-lists/domain";

import { useWatch } from "antd/es/form/Form";
import classNames from "classnames";
import type { ColumnsSettings } from "../../../../../../../components/ag-grid/object-table/domain";
import { grey } from "../../../../../../../utils/colorPalette";
import { AdditionalSider } from "./AdditionalSider";
import { ColumnSelector } from "./ColumnSelector";
import { ColumnSettingsEditor } from "./ColumnSettingsEditor";
import "./genericEditorStyle.scss";
import { MoveActions } from "./MoveActions";
import { SortSelector } from "./SortSelector";

type ColumnGroupSelectorProps = {
  availableColumns: AvailableColumn[];
  label: string;
  name: string[];
  formInstance: FormInstance;
};

export const ColumnGroupSelector = ({
  availableColumns,
  label,
  name,
  formInstance,
}: ColumnGroupSelectorProps) => {
  // Because Antd Form don't allow conditional rendering on Form.Item...
  const [fieldNameBeingEdited, setFieldNameBeingEdited] = useState<
    number | undefined
  >(undefined);

  const showAll = useWatch<string | undefined>(
    ["config", "options", "allColumnGroupSelectedProps", "show"],
    formInstance
  );

  return (
    <ChartOptionLine
      items={[
        {
          content: (
            <Flex vertical className="column-group-selector">
              <Typography.Text className="label">{label}</Typography.Text>

              <Form.List name={name}>
                {(fields, { add, remove, move }) => (
                  <Flex vertical gap="small">
                    {fields.map((field, index) => {
                      const moveDown = () => {
                        index < fields.length - 1 && move(index, index + 1);
                      };
                      const moveUp = () => {
                        index > 0 && move(index, index - 1);
                      };

                      return (
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          key={`columnGroup-${field.name}-${field.key}`}
                          name={[field.name]}
                        >
                          <ColumnGroupElement
                            fieldName={field.name}
                            availableColumns={availableColumns}
                            remove={() => remove(field.name)}
                            fieldNameBeingEdited={fieldNameBeingEdited}
                            setFieldNameBeingEdited={setFieldNameBeingEdited}
                            moveDown={moveDown}
                            moveUp={moveUp}
                            formInstance={formInstance}
                          />
                        </Form.Item>
                      );
                    })}

                    <Button
                      size="small"
                      block
                      onClick={() => add({ name: "New column group" })}
                    >
                      Add
                    </Button>
                  </Flex>
                )}
              </Form.List>

              <div
                style={{
                  marginTop: 8,
                  background: grey[3],
                  padding: 6,
                  borderRadius: 6,
                }}
              >
                <Flex vertical gap="small">
                  <Flex justify="space-between" align="center">
                    <Typography.Text>Show "All" option :</Typography.Text>
                    <Form.Item
                      valuePropName="checked"
                      name={[
                        "config",
                        "options",
                        "allColumnGroupSelectedProps",
                        "show",
                      ]}
                      noStyle
                    >
                      <Switch
                        size="small"
                        onChange={(checked) =>
                          !checked &&
                          formInstance.setFieldValue(
                            [
                              "config",
                              "options",
                              "allColumnGroupSelectedProps",
                              "name",
                            ],
                            undefined
                          )
                        }
                      />
                    </Form.Item>
                  </Flex>
                  {showAll && (
                    <Flex gap="small" align="center">
                      <Typography.Text>Label :</Typography.Text>
                      <Form.Item
                        noStyle
                        name={[
                          "config",
                          "options",
                          "allColumnGroupSelectedProps",
                          "name",
                        ]}
                      >
                        <Input
                          size="small"
                          style={{ width: "auto", flex: 1 }}
                        />
                      </Form.Item>
                    </Flex>
                  )}
                </Flex>
              </div>
            </Flex>
          ),
          flex: 1,
        },
      ]}
    />
  );
};

type ColumnGroupElementProps = {
  value?: IColumnGroup;
  fieldName: number;
  availableColumns: AvailableColumn[];
  remove: () => void;
  fieldNameBeingEdited: number | undefined;
  setFieldNameBeingEdited: (value: number | undefined) => void;
  moveDown: () => void;
  moveUp: () => void;
  formInstance: FormInstance;
};
const ColumnGroupElement = ({
  value,
  fieldName,
  availableColumns,
  remove,
  fieldNameBeingEdited,
  setFieldNameBeingEdited,
  moveDown,
  moveUp,
  formInstance,
}: ColumnGroupElementProps) => {
  const open = fieldName === fieldNameBeingEdited;
  const { name, isDefault, columns, columnsSettings } = value ?? {};

  return (
    <>
      <AdditionalSider
        open={open}
        onClose={() => setFieldNameBeingEdited(undefined)}
        title="Edit column group"
      >
        <ColumnGroupElementEditor
          fieldName={fieldName}
          availableColumns={availableColumns}
          selectedColumns={columns}
          formInstance={formInstance}
          currentColumnsSettings={columnsSettings}
        />
      </AdditionalSider>

      <div
        className={classNames("column-group-element", isDefault && "default")}
      >
        <Flex gap="small" justify="space-between">
          <Flex gap="small" align="center">
            <MoveActions moveDown={moveDown} moveUp={moveUp} />

            <Typography.Text strong ellipsis>
              {name}
            </Typography.Text>
          </Flex>

          <Flex gap="small">
            <Tooltip title="Edit">
              <Button
                size="small"
                type="text"
                onClick={() =>
                  setFieldNameBeingEdited(open ? undefined : fieldName)
                }
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title="Remove">
              <Button
                size="small"
                type="text"
                onClick={remove}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

type ColumnGroupElementEditorProps = {
  fieldName: number;
  availableColumns: AvailableColumn[];
  selectedColumns?: string[] | undefined;
  formInstance: FormInstance;
  currentColumnsSettings?: ColumnsSettings;
};
const ColumnGroupElementEditor = ({
  fieldName,
  availableColumns,
  selectedColumns,
  formInstance,
  currentColumnsSettings,
}: ColumnGroupElementEditorProps) => {
  return (
    <Flex vertical className="column-group-element-editor">
      <Form.Item label="Name" name={[fieldName, "name"]}>
        <Input size="small" />
      </Form.Item>

      <ColumnSelector
        availableColumns={availableColumns}
        label="Columns"
        name={[fieldName, "columns"]}
      />

      <SortSelector
        availableColumns={availableColumns}
        label="Sort by"
        name={[fieldName, "sortBy"]}
      />

      <Flex justify="space-between" align="center">
        <Typography.Text>Default :</Typography.Text>
        <Form.Item
          valuePropName="checked"
          name={[fieldName, "isDefault"]}
          noStyle
        >
          <Switch size="small" />
        </Form.Item>
      </Flex>

      {selectedColumns && (
        <Flex vertical gap="small" style={{ marginTop: 8 }}>
          <Typography.Text>Columns settings :</Typography.Text>

          <ColumnSettingsEditor
            name={[fieldName, "columnsSettings"]}
            formInstance={formInstance}
            availableColumns={availableColumns}
            selectedColumns={selectedColumns}
            currentColumnsSettings={currentColumnsSettings}
          />
        </Flex>
      )}
    </Flex>
  );
};
