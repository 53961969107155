import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import {
  type AvailableColumn,
  getObjectColumns,
  isAvailableMetric,
  isAvailableProperty,
} from "../../../../../../../object/domain";
import { type IWidgetSparkline, getForeignObject } from "../domain";
import type { IBarSparklineRendererProps } from "./BarSparklineRenderer";

type IUseBarSparklineConfigOptions = {
  conf: IWidgetSparkline;
  query: Query | undefined;
  object: IObject;
};

export function useBarSparklineConfig(
  options: IUseBarSparklineConfigOptions
): [
  IBarSparklineRendererProps["config"] | undefined,
  AvailableColumn[] | undefined
] {
  const { conf, query, object } = options;
  const [config, setConfig] = useState<
    IBarSparklineRendererProps["config"] | undefined
  >();
  const [availableColumns, setAvailableColumns] = useState<AvailableColumn[]>();

  useEffect(() => {
    try {
      if (!conf.barConfig?.foreignObjectPropertyId) {
        throw new Error("foreignObjectPropertyId must be configured");
      }

      const foreignObject = getForeignObject(
        object,
        conf.barConfig.foreignObjectPropertyId
      );
      const columns = foreignObject ? getObjectColumns(foreignObject) : [];
      const metric = columns
        .filter(isAvailableMetric)
        .find((ap) => ap.data.key === conf.barConfig?.metric[0]);
      const dimension = columns
        .filter(isAvailableProperty)
        .find((ap) => ap.data.key === conf.barConfig?.groupBy[0]);

      if (!metric || !dimension) {
        throw new Error("Metric and dimension must be set");
      }
      const newConfig: IBarSparklineRendererProps["config"] = {
        x: {
          key: dimension.data.key,
        },
        y: {
          key: metric.data.key,
          formatter: metric.data.formatter,
        },
        options: {
          highlightLabel: conf.barConfig.highlightLabel,
        },
        tooltip: conf.barConfig?.tooltip,
      };
      if (!_.isEqual(newConfig, config)) {
        setConfig(newConfig);
      }
      if (!_.isEqual(columns, availableColumns)) {
        setAvailableColumns(availableColumns);
      }
    } catch (error) {
      console.warn(error);
      if (config) {
        setConfig(undefined);
      }
    }
  }, [config, object, query, conf.barConfig]);

  return [config, availableColumns];
}
