import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Space, Typography, type FormInstance } from "antd";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IReport } from "../../../../../../../../interfaces/reports";
import graphQlService from "../../../../../../../../services/graphql/GraphQLService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetLinkCollectionConfig } from "../domain";
import { LinkAdditionModal } from "./LinkAdditionModal";
import { LinkCollapse } from "./LinkCollapse";

export interface IWidgetLinkCollectionEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetLinkCollectionConfig;
  form: FormInstance;
}

type Props = IWidgetLinkCollectionEditorProps & InjectedOrgProps;

function WidgetLinkCollectionEditor(props: Props) {
  const { form, org } = props;
  const [state, setState] = React.useState<
    AsyncData<{ allObjects: IObject[]; allReports: IReport[] }>
  >({ status: "initial" });
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        setState({ status: "loading" });
        const d = await graphQlService<{
          allObjects: IObject[];
          allReports: IReport[];
        }>(
          `
query allLinkeableItems($orgId: ID!) {
  allObjects(where: { isDeleted_not: true, org: { id: $orgId } }) {
     id
     name
     views(where: { isDeleted_not: true, isPersonal: false }) {
          id
          name
          type
     }
  }
   allReports(where: { deleted_not: true, org: { id: $orgId } }) {
     id
     name
     type
  }
}
          `,
          {
            orgId: org.id,
          }
        );
        setState({ status: "success", data: d });
      } catch (err) {
        console.error(err);
        setState({ status: "error", error: err });
      }
    };
    if (state.status === "initial") {
      fetch();
    }
  }, [org.id]);

  if (state.status === "initial" || state.status === "loading") {
    return (
      <ChartOptionCollapse dark title="Link Collection">
        <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      </ChartOptionCollapse>
    );
  }

  if (state.status === "error") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Text type="danger">
          There was an error fetching your content
        </Typography.Text>
      </div>
    );
  }

  return (
    <>
      <ChartOptionCollapse dark title="Link Collection">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <Form.Item shouldUpdate>
            {() => (
              <Form.List name={["config", "links"]}>
                {(fields, { add, remove, move }) => {
                  return (
                    <ChartOptionLine
                      items={[
                        {
                          content: (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: 4,
                                  marginBottom: 12,
                                }}
                              >
                                <div style={{ flex: 1 }}>Links</div>
                                <div style={{ flex: `0 auto` }}>
                                  <Button
                                    size="small"
                                    onClick={() => setModalOpen(true)}
                                    icon={<PlusOutlined />}
                                  />
                                  <LinkAdditionModal
                                    open={modalOpen}
                                    onClose={() => setModalOpen(false)}
                                    onSubmit={(i) => {
                                      add(i);
                                    }}
                                    allObjects={state.data.allObjects}
                                    allReports={state.data.allReports}
                                  />
                                </div>
                              </div>
                              <div>
                                <Flex vertical gap="small">
                                  {fields && fields.length ? (
                                    fields.map((field, index) => {
                                      const moveDown = () => {
                                        index < fields.length - 1 &&
                                          move(index, index + 1);
                                      };
                                      const moveUp = () => {
                                        index > 0 && move(index, index - 1);
                                      };

                                      const value = form.getFieldValue([
                                        "config",
                                        "links",
                                        index,
                                        "id",
                                      ]);

                                      const renderName = () => {
                                        if (!value) {
                                          return "Untitled";
                                        }
                                        if (value.startsWith("object::")) {
                                          const id = value.replace(
                                            "object::",
                                            ""
                                          );
                                          const found =
                                            state.data.allObjects.find(
                                              (o) => o.id === id
                                            );
                                          if (found) {
                                            return found.name;
                                          }
                                        }
                                        console.log(value);
                                        if (value.startsWith("object-view::")) {
                                          const [view, obj] = value.split("|");

                                          const viewId = view.replace(
                                            "object-view::",
                                            ""
                                          );
                                          const objId = obj.replace(
                                            "object::",
                                            ""
                                          );
                                          const foundObject =
                                            state.data.allObjects.find(
                                              (o) => o.id === objId
                                            );
                                          const foundView =
                                            foundObject?.views.find(
                                              (v) => v.id === viewId
                                            );
                                          if (foundObject && foundView) {
                                            return `${foundObject.name} (${foundView.name})`;
                                          }
                                        }
                                        if (value.startsWith("report::")) {
                                          const id = value.replace(
                                            "report::",
                                            ""
                                          );
                                          const found =
                                            state.data.allReports.find(
                                              (o) => o.id === id
                                            );
                                          if (found) {
                                            return found.name;
                                          }
                                        }
                                        return "Not found";
                                      };

                                      return (
                                        <LinkCollapse
                                          baseName={[field.name]}
                                          displayName={renderName()}
                                          key={`link-collection-${field.name}`}
                                          collapseKey={`link-collection-${field.name}`}
                                          moveDown={moveDown}
                                          moveUp={moveUp}
                                          remove={() => remove(index)}
                                          formInstance={form}
                                        />
                                      );
                                    })
                                  ) : (
                                    <div
                                      style={{
                                        height: 100,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      Add a link
                                    </div>
                                  )}
                                </Flex>
                              </div>
                            </div>
                          ),
                          flex: 1,
                        },
                      ]}
                    />
                  );
                }}
              </Form.List>
            )}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>
    </>
  );
}

export default compose<Props, IWidgetLinkCollectionEditorProps>(WithOrg)(
  WidgetLinkCollectionEditor
);
