import { Skeleton, Space } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetLinkCollectionConfig } from "./domain";
import WidgetLinkCollectionEditor from "./editor/WidgetLinkCollectionEditor";
import WidgetLinkCollection from "./widget/WidgetLinkCollection";

export const widgetLinkCollectionDefinition: IRendererConfig<IWidgetLinkCollectionConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IWidgetLinkCollectionConfig = {
        links: [],
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IWidgetLinkCollectionConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    removeCard: true,
    renderEditor: (widget, object, record, conf, form, org) => {
      return (
        <WidgetLinkCollectionEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
        />
      );
    },
    renderComponent: (
      widget,
      object,
      record,
      layoutId,
      recordId,
      conf,
      edit
    ) => {
      return (
        <WidgetLinkCollection
          widget={widget}
          object={object}
          record={record}
          layoutId={layoutId}
          recordId={recordId}
          conf={conf}
          edit={edit}
        />
      );
    },
    renderLoadingComponent: (widget, conf) => (
      <Space wrap size="large">
        {Array(5)
          .fill(1)
          .map((_, i) => {
            return (
              <Skeleton.Button
                key={i}
                size="small"
                active
                block
                shape="default"
                style={{ width: 250 }}
              />
            );
          })}
      </Space>
    ),
  };
