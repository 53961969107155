import type { Query } from "@cubejs-client/core";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import { convertPropertyToAvailableProperties } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { getForeignObject } from "../../widgets/kpi/renderer/sparkline/domain";
import type { IFilterEditorValue } from "../../widgets/related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../../widgets/related-lists/domain";

export interface IEmailWidgetRepeatableConfig {
  foreignObjectPropertyId?: string;
  type?: RepeatableType;
  fallback?: {
    content?: string;
  };
  options?: {
    foreignObjectSelectedlayout?: string;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    limit?: number;
    filters?: IFilterEditorValue;
  };
}

type RepeatableType = "LIST";

export const getEmailWidetRepeatableQueryConfig = (options: {
  object: IObject;
  record: IRecord;
  conf: IEmailWidgetRepeatableConfig;
}):
  | { isValid: false }
  | {
      isValid: true;
      query: Query;
      dimensionKey: string;
      foreignObject: IObject;
    } => {
  const { object, record, conf } = options;

  const config = conf;

  if (!config || !config.foreignObjectPropertyId) {
    return { isValid: false };
  }

  const foreignObject = getForeignObject(
    object,
    config.foreignObjectPropertyId
  );

  if (!foreignObject) {
    return { isValid: false };
  }

  const property = foreignObject.properties.find(
    (p) => p.id === config?.foreignObjectPropertyId
  );

  if (!property) {
    return { isValid: false };
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );

  const dimensions = [`${foreignObject.table.cubeName}.id`];
  const recordFilter = {
    recordForeignKey: `${foreignAvailable.key}_raw`,
    recordId: record[`${object.table.cubeName}.id`] as string,
  };

  return {
    isValid: true,
    query: buildQueryFromDimensionAndMetrics(
      dimensions,
      [],
      recordFilter,
      conf.options?.filters,
      conf.options?.sortBy,
      conf.options?.limit
    ),
    dimensionKey: `${foreignObject.table.cubeName}.id`,
    foreignObject: foreignObject,
  };
};
