import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
  isAvailableMetric,
} from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import {
  convertKeyToMarkdocVariable,
  extractVariableFromMarkdocAst,
} from "../../common/markdoc/domain";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../related-lists/domain";

export interface IWidgetKPIConfig extends BaseConfig {
  columns: Array<string>;
  label?: string;
  secondary?: string;
  theme?: string;
  type?: "table" | "bar" | "progress" | "progressTable" | "none";
  barConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    tooltip?: string;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
  tableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
  progressTableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    secondMetric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
}
type theme = "gray" | "blue" | "orange" | "yellow" | "green";

export type kpiTheme = {
  background: string;
  primary: string;
  secondary: string;
};

export const kpiThemes: {
  [key in theme]: kpiTheme;
} = {
  gray: {
    background: "#F7F7F8",
    primary: "#4C4C4C",
    secondary: "#AAAAAA",
  },
  blue: {
    background: "#E7F0FF",
    primary: "#184D80",
    secondary: "#85A1C1",
  },
  orange: {
    background: "#FFEFE6",
    primary: "#8D3300",
    secondary: "#BA988E",
  },
  yellow: {
    background: "#FFF5DA",
    primary: "#785800",
    secondary: "#B6A685",
  },
  green: {
    background: "#E6FAE8",
    primary: "#00640A",
    secondary: "#79ad75",
  },
};

