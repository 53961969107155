import type { Filter } from "@cubejs-client/core";
import { Flex } from "antd";
import { findLastIndex } from "lodash";
import { useState } from "react";
import type {
  ColumnSettings,
  ColumnsSettings,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import { useWidgetCacheActions } from "../../../../../../../../store/widgetCacheStore";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IColumnGroup, IConfiguration } from "../domain";
import { type IRelatedListConfig } from "../domain";
import { RelatedListActions } from "./RelatedListActions";
import RelatedListQuery from "./RelatedListQuery";

interface IRecordRelatedListProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IRelatedListConfig;
  edit?: boolean;
}

type Props = InjectedOrgProps & IRecordRelatedListProps;

function RecordRelatedList(props: Props) {
  const { conf, record, widget, object, layoutId, recordId, edit } = props;
  const widgetCacheProps = {
    widgetId: widget.id,
    objectId: object.id,
    layoutId,
    recordId,
  };

  const [selectedConfiguration, setSelectedConfiguration] = useState<
    IConfiguration | undefined
  >((conf?.options?.configurations ?? []).find((c) => c.isDefault));
  const [selectedColumnGroups, setSelectedColumnGroups] = useState<
    IColumnGroup[]
  >((conf?.options?.columnGroups ?? []).filter((c) => c.isDefault));
  const { setAsFinished } = useWidgetCacheActions(widgetCacheProps);

  if (!conf.foreignObjectPropertyId) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Missing configuration</Feednack>
      </div>
    );
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </div>
    );
  }

  if (!conf.options?.columns?.length) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Please add at least one column to display</Feednack>
      </div>
    );
  }

  const foreignObject = foreignProperty.object;

  const property = foreignObject.properties.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!property) {
    setAsFinished(true);
    return (
      <div style={{ height: 250 }}>
        <Feednack>Property must be defined</Feednack>
      </div>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const cubeName = object.table.cubeName;
  const recordFilters = [
    {
      member: `${foreignAvailable.key}_raw`,
      operator: "equals",
      values: [record[`${cubeName}.id`] as string],
    },
  ];
  const availableColumns = getObjectColumns(foreignObject);

  const columns = [
    ...conf.options.columns,
    ...selectedColumnGroups.reduce(
      (acc, { columns }) => [...acc, ...(columns ?? [])],
      []
    ),
  ].filter((value, i, values) => {
    return findLastIndex(values, (v) => v === value) === i;
  });

  const additionalFilters = conf.options.filters;

  const sortBy = [
    ...(conf.options?.sortBy ?? []),
    ...(selectedConfiguration?.sortBy ?? []),
  ].filter(([label], index, values) => {
    return findLastIndex(values, (v) => v[0] === label) === index;
  });

  const groupBy = [
    ...(conf.options?.groupBy ?? []),
    ...(selectedConfiguration?.groupBy ?? []),
  ].filter(({ id }, i, values) => {
    return findLastIndex(values, (v) => v.id === id) === i;
  });

  const hidePagination = conf.options?.hidePagination;
  const showRowNumber = conf.options?.showRowNumber;

  const columnsSettings: ColumnsSettings = {
    ...(conf.options?.columnsSettings ?? {}),
    ...selectedColumnGroups.reduce((acc, { columnsSettings }) => {
      return { ...acc, ...(columnsSettings ?? {}) };
    }, {}),
  };

  const filters = [
    {
      and: [
        ...recordFilters,
        additionalFilters
          ? { [additionalFilters.operator]: additionalFilters.filters }
          : { and: [] },
        selectedConfiguration?.filters
          ? {
              [selectedConfiguration.filters.operator]:
                selectedConfiguration.filters.filters,
            }
          : { and: [] },
      ],
    },
  ] as Filter[];

  const sc = selectedConfiguration;
  const scGBCS =
    sc && sc.groupBy && sc.groupBy.length > 0 && sc.groupByColumnSettings
      ? sc.groupByColumnSettings
      : {};
  const confGBCS = conf.options.groupByColumnSettings ?? {};
  const groupByColumnSettings: ColumnSettings = {
    label: scGBCS.label || confGBCS.label,
    displayImage: scGBCS.displayImage ?? confGBCS.displayImage,
    initialSizeType: scGBCS.initialSizeType ?? confGBCS.initialSizeType,
    initialSizeWidth: scGBCS.initialSizeWidth ?? confGBCS.initialSizeWidth,
  };

  return (
    <Flex vertical>
      {(conf.options?.configurations || conf.options?.columnGroups) && (
        <div style={{ marginBottom: 24 }}>
          <RelatedListActions
            configurations={conf.options.configurations ?? []}
            selectedConfiguration={selectedConfiguration}
            onConfigurationChange={(s) => setSelectedConfiguration(s)}
            columnGroups={conf.options.columnGroups ?? []}
            selectedColumnGroups={selectedColumnGroups}
            onColumnGroupsChange={(s) => setSelectedColumnGroups(s)}
            showAllColumnGroupOption={
              !!conf.options.allColumnGroupSelectedProps?.show
            }
            allColumnGroupOptionName={
              conf.options.allColumnGroupSelectedProps?.name
            }
          />
        </div>
      )}
      <RelatedListQuery
        object={foreignObject}
        availableColumns={availableColumns}
        pageSize={conf.options.pageSize || 10}
        filters={filters}
        sortBy={sortBy}
        groupBy={groupBy}
        columns={columns}
        columnsSettings={columnsSettings}
        groupByColumnSettings={groupByColumnSettings}
        hidePagination={hidePagination}
        showRowNumber={showRowNumber}
        widgetCacheProps={widgetCacheProps}
      />
    </Flex>
  );
}

export default compose<Props, IRecordRelatedListProps>(WithOrg)(
  RecordRelatedList
);
