import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import {
  getObjectColumns,
  isAvailableMetric,
  isAvailableProperty,
} from "../../../../../../../object/domain";
import { getForeignObject, type IWidgetSparkline } from "../domain";
import type { IProgressTableSparklineRendererProps } from "./ProgressTableSparklineRenderer";

type IUseProgressTableSparklineConfigOptions = {
  conf: IWidgetSparkline;
  query: Query | undefined;
  object: IObject;
};

export function useProgressTableSparklineConfig(
  options: IUseProgressTableSparklineConfigOptions
) {
  const { conf, query, object } = options;
  const [config, setConfig] = useState<
    IProgressTableSparklineRendererProps["config"] | undefined
  >();

  useEffect(() => {
    try {
      if (!conf.progressTableConfig?.foreignObjectPropertyId) {
        throw new Error("foreignObjectPropertyId must be configured");
      }

      const foreignObject = getForeignObject(
        object,
        conf.progressTableConfig.foreignObjectPropertyId
      );

      const columns = foreignObject ? getObjectColumns(foreignObject) : [];
      const metric = columns
        .filter(isAvailableMetric)
        .find((ap) => ap.data.key === conf.progressTableConfig?.metric[0]);
      const secondMetric = columns
        .filter(isAvailableMetric)
        .find(
          (ap) => ap.data.key === conf.progressTableConfig?.secondMetric[0]
        );
      const dimension = columns
        .filter(isAvailableProperty)
        .find((ap) => ap.data.key === conf.progressTableConfig?.groupBy[0]);

      if (!metric || !dimension || !secondMetric) {
        throw new Error("Metrics and dimension must be set");
      }

      const newConfig = {
        x: {
          key: dimension.data.key,
        },
        y: {
          key: metric.data.key,
          formatter: metric.data.formatter,
        },
        options: {
          highlightLabel: conf.progressTableConfig.highlightLabel,
        },
        secondMetric: {
          key: secondMetric?.data.key,
          formatter: secondMetric?.data.formatter,
        },
      };
      if (!_.isEqual(newConfig, config)) {
        setConfig(newConfig);
      }
    } catch (error) {
      console.warn(error);
      if (config) {
        setConfig(undefined);
      }
    }
  }, [config, object, query, conf.progressTableConfig]);

  return config;
}
