import type { FormInstance } from "antd";
import { Form, InputNumber, Select, Space, Switch, Typography } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import { getAvailableDimensions } from "../../../../../object/viewer/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { ColumnGroupSelector } from "../../generic-editor-parts/ColumnGroupSelector";
import { ColumnSelector } from "../../generic-editor-parts/ColumnSelector";
import {
  ColumnSettingsEditor,
  SingleColumnSettingsEditor,
} from "../../generic-editor-parts/ColumnSettingsEditor";
import { ConfigurationSelector } from "../../generic-editor-parts/ConfigurationSelector";
import { FilterSelector } from "../../generic-editor-parts/FilterSelector";
import { GroupSelector } from "../../generic-editor-parts/GroupSelector";
import { SortSelector } from "../../generic-editor-parts/SortSelector";
import type { IRelatedListConfig } from "../domain";

interface IWidgetRelatedListEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IRelatedListConfig;
  form: FormInstance;
}

type Props = IWidgetRelatedListEditorProps & InjectedOrgProps;

function WidgetRelatedListEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;

  const currentPropertyId = useWatch<string | undefined>(
    ["config", "foreignObjectPropertyId"],
    form
  );
  const selectedColumns = useWatch<string[] | undefined>(
    ["config", "options", "columns"],
    form
  );

  const objectProperty = useMemo(() => {
    return object.foreignKeys.find(({ id }) => id === currentPropertyId);
  }, [currentPropertyId, object.foreignKeys]);

  const availableColumns = useMemo(() => {
    return objectProperty ? getObjectColumns(objectProperty.object) : undefined;
  }, [objectProperty]);

  const availableDimensionsToGroup = useMemo(() => {
    return availableColumns
      ? getAvailableDimensions(
          availableColumns.filter(({ type, data }) => {
            return type !== "property" || data.type !== "primaryKey";
          }),
          {
            type: "display",
          }
        )
      : undefined;
  }, [availableColumns]);

  const availableDimensionsToFilter = useMemo(() => {
    return availableColumns
      ? getAvailableDimensions(availableColumns, { type: "sortAndFilter" })
      : undefined;
  }, [availableColumns]);

  return (
    <>
      <ChartOptionCollapse dark title="Related List Configuration">
        <Space style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Related Object"
                    required
                    style={{ marginBottom: 4 }}
                    name={["config", "foreignObjectPropertyId"]}
                  >
                    <Select
                      optionLabelProp="label"
                      style={{ width: "100%", maxWidth: "100%" }}
                      popupMatchSelectWidth={false}
                    >
                      {object.foreignKeys.map((ok) => {
                        return (
                          <Select.Option
                            label={ok.object.name}
                            key={ok.id}
                            value={ok.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {ok.object.name}{" "}
                              <Typography.Text type="secondary">
                                on {ok.label}
                              </Typography.Text>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />

          {availableColumns ? (
            <ColumnSelector
              availableColumns={availableColumns}
              label="Always displayed columns"
              name={["config", "options", "columns"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "columns"]} />
          )}

          {availableColumns &&
          availableDimensionsToGroup &&
          availableDimensionsToFilter ? (
            <ConfigurationSelector
              availableColumns={availableColumns}
              availableDimensionsToGroup={availableDimensionsToGroup}
              availableDimensionsToFilter={availableDimensionsToFilter}
              label="Configurations"
              name={["config", "options", "configurations"]}
              org={org}
              object={object}
            />
          ) : (
            <Form.Item
              noStyle
              hidden
              name={["config", "options", "configurations"]}
            />
          )}

          {availableColumns ? (
            <ColumnGroupSelector
              availableColumns={availableColumns}
              label="Columns groups"
              name={["config", "options", "columnGroups"]}
              formInstance={form}
            />
          ) : (
            <Form.Item
              noStyle
              hidden
              name={["config", "options", "columnGroups"]}
            />
          )}

          {availableColumns ? (
            <SortSelector
              availableColumns={availableColumns}
              label="Base sort"
              name={["config", "options", "sortBy"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "sortBy"]} />
          )}

          {availableDimensionsToGroup ? (
            <GroupSelector
              availableDimensions={availableDimensionsToGroup}
              label="Base group"
              name={["config", "options", "groupBy"]}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "groupBy"]} />
          )}

          {availableColumns ? (
            <SingleColumnSettingsEditor
              name={["config", "options", "groupByColumnSettings"]}
              label="Group by column settings"
              formInstance={form}
            />
          ) : (
            <Form.Item
              noStyle
              hidden
              name={["config", "options", "groupByColumnSettings"]}
            />
          )}

          {availableDimensionsToFilter ? (
            <FilterSelector
              availableDimensions={availableDimensionsToFilter}
              label="Base filters"
              name={["config", "options", "filters"]}
              org={org}
              object={object}
            />
          ) : (
            <Form.Item noStyle hidden name={["config", "options", "filters"]} />
          )}

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Hide pagination",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "hidePagination"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Row number",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "showRowNumber"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <Form.Item noStyle shouldUpdate>
            {() => {
              const hidePagination = form.getFieldValue([
                "config",
                "options",
                "hidePagination",
              ]);
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: hidePagination ? "Limit" : "Page size",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item
                          noStyle
                          name={["config", "options", "pageSize"]}
                        >
                          <InputNumber size="small" />
                        </Form.Item>
                      ),
                      flex: 0,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>

      {availableColumns && selectedColumns && selectedColumns.length > 0 ? (
        <ChartOptionCollapse dark title="Columns Settings">
          <Space style={{ width: "100%" }} direction="vertical">
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <ColumnSettingsEditor
                name={["config", "options", "columnsSettings"]}
                availableColumns={availableColumns}
                selectedColumns={selectedColumns}
                formInstance={form}
              />
            </Space>
          </Space>
        </ChartOptionCollapse>
      ) : (
        <Form.Item
          noStyle
          hidden
          name={["config", "options", "columnsSettings"]}
        />
      )}
    </>
  );
}

export default compose<Props, IWidgetRelatedListEditorProps>(WithOrg)(
  WidgetRelatedListEditor
);
