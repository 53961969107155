import { LayoutOutlined } from "@ant-design/icons";
import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import { Button, Skeleton, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { type RouteComponentProps, withRouter } from "react-router";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../../../../components/icons/holder/IconHolder";
import { ReportIcon } from "../../../../../../../../components/report/ReportIcon";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type {
  IObject,
  IObjectView,
} from "../../../../../../../../interfaces/object";
import type { IReport } from "../../../../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../../../../routes/routes";
import graphQlService from "../../../../../../../../services/graphql/GraphQLService";
import { grey } from "../../../../../../../../utils/colorPalette";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { ILinkItem, IWidgetLinkCollectionConfig } from "../domain";

export interface IWidgetLinkCollectionProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  layoutId: string;
  recordId: string;
  conf: IWidgetLinkCollectionConfig;
  edit?: boolean;
}

type Props = IWidgetLinkCollectionProps &
  InjectedOrgProps &
  RouteComponentProps;

function WidgetLinkCollection(props: Props) {
  const {
    conf,
    edit,
    history,
    match: { params },
  } = props;

  const [state, setState] = React.useState<
    AsyncData<{
      allObjectViews: IObjectView[];
      allReports: IReport[];
      allObjects: IObject[];
    }>
  >({ status: "initial" });

  const properLinks = (conf.links || [])?.filter((f) => !!f.id);

  useEffect(() => {
    const fetch = async () => {
      try {
        setState({ status: "loading" });
        const d = await graphQlService<{
          allObjectViews: IObjectView[];
          allObjects: IObject[];
          allReports: IReport[];
        }>(
          `
query allLinkInCollection($reportIds: [ID!]!, $objectViewIds: [ID!], $objectIds: [ID!]) {
  allObjectViews(where: {id_in: $objectViewIds }) {
    id
    slug
    name
    object {
      slug
      name
      color
    	icon
    }
  }
  allObjects(where: { id_in: $objectIds }) {
      id
      slug
      name
      color
      icon
  }
  allReports(where: {id_in: $reportIds}) {
    id
    slug
    name
    type
  }
}
          `,
          {
            reportIds:
              properLinks
                ?.filter((r) => r.id?.startsWith("report::"))
                .map((r) => r.id?.replace("report::", "")) || [],
            objectIds:
              properLinks
                ?.filter((r) => r.id?.startsWith("object::"))
                .map((r) => r.id?.replace("object::", "")) || [],
            objectViewIds:
              properLinks
                ?.filter((r) => r.id?.startsWith("object-view::"))
                .map((r) =>
                  r.id?.split("|")?.[0].replace("object-view::", "")
                ) || [],
          }
        );
        setState({ status: "success", data: d });
      } catch (err) {
        console.error(err);
        setState({ status: "error", error: err });
      }
    };
    if (state.status === "initial" && properLinks.length) {
      fetch();
    }
  }, [properLinks]);

  if (edit && !properLinks.length) {
    return (
      <Space wrap>
        <Button size="small">Example link</Button>
      </Space>
    );
  }

  if (state.status === "initial" || state.status === "loading") {
    const comps = new Array(conf.links?.length ? conf.links?.length : 5)
      .fill(1)
      .map((_, i) => {
        return (
          <Skeleton.Button
            key={i}
            size="small"
            active
            block
            shape="default"
            style={{ width: 100 }}
          />
        );
      });
    return <Space wrap>{comps}</Space>;
  }

  if (state.status === "error") {
    return (
      <div>
        <Typography.Text type="danger">
          There was an error fetching your data, please reload your page
        </Typography.Text>
      </div>
    );
  }

  const renderButton = (
    key: string,
    icon: ReactJSXElement,
    text: ReactJSXElement,
    onClick: () => void
  ) => {
    const ButtonIcon = styled.div`
      display: flex;
      justify-content: center;
      gap: 8px;
      max-width: 250px;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid ${grey[5]};
      padding: 0px 8px;
      transition: 0.2s background-color;

      &:hover {
        background-color: ${grey[2]};
      }
    `;

    return (
      <ButtonIcon
        key={key}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 8,
          maxWidth: 250,
          alignItems: "center",
          cursor: "pointer",
          borderRadius: 6,
          border: `1px solid ${grey[5]}`,
          padding: `2px 8px`,
        }}
        onClick={onClick}
      >
        <div style={{ flex: `0 auto` }}>{icon}</div>
        <div style={{ flex: 1 }}>{text}</div>
      </ButtonIcon>
    );
  };

  const renderComponent = (c: ILinkItem): Array<ReactJSXElement> => {
    if (c.id?.startsWith("object-view::")) {
      const [objectView] = c.id.split("|");
      const id = objectView.replace("object-view::", "");
      const f = state.data.allObjectViews.find((ov) => ov.id === id);
      if (!f || !f.object) {
        return [];
      }

      const icon = (
        <IconHolder
          size={16}
          color="#F9F8F8"
          background={f.object?.color || "#F1BD6C"}
          icon={
            f.object?.icon ? (
              <WlyDynamicIcon name={f.object?.icon} />
            ) : (
              <LayoutOutlined color="#F9F8F8" />
            )
          }
        />
      );
      return [
        renderButton(
          `ov-${c.id}`,
          icon,
          <Typography.Text ellipsis>
            {c.overrideName ? c.overrideName : `${f.object?.name} (${f.name})`}
          </Typography.Text>,
          () =>
            history.push(
              routeDescriptor["object"].renderRoute(
                { ...params, objectSlug: f.object?.slug },
                { view: f.slug }
              )
            )
        ),
      ];
    } else if (c.id?.startsWith("object::")) {
      const id = c.id.replace("object::", "");
      const f = state.data.allObjects.find((ov) => ov.id === id);
      if (!f) {
        return [];
      }
      const icon = (
        <div style={{ display: "flex" }}>
          <IconHolder
            size={16}
            color="#F9F8F8"
            background={f.color || "#F1BD6C"}
            icon={
              f.icon ? (
                <WlyDynamicIcon name={f.icon} />
              ) : (
                <LayoutOutlined color="#F9F8F8" />
              )
            }
          />
        </div>
      );
      return [
        renderButton(
          `o-${c.id}`,
          icon,
          <Typography.Text ellipsis>
            {c.overrideName ? c.overrideName : f.name}
          </Typography.Text>,
          () =>
            history.push(
              routeDescriptor["object"].renderRoute({
                ...params,
                objectSlug: f.slug,
              })
            )
        ),
      ];
    } else if (c.id?.startsWith("report::")) {
      const id = c.id.replace("report::", "");
      const f = state.data.allReports.find((ov) => ov.id === id);
      if (!f) {
        return [];
      }

      const icon = <ReportIcon type={f.type} />;

      return [
        renderButton(
          `rp-${c.id}`,
          icon,
          <Typography.Text ellipsis>
            {c.overrideName ? c.overrideName : f.name}
          </Typography.Text>,
          () =>
            history.push(
              routeDescriptor["report"].renderRoute({
                ...params,
                reportSlug: f.slug,
              })
            )
        ),
      ];
    }
    return [];
  };

  const comps = (properLinks || [])
    .filter((l) => !!l.id)
    .flatMap(renderComponent);

  return <Space>{comps}</Space>;
}

export default compose<Props, IWidgetLinkCollectionProps>(
  WithOrg,
  withRouter
)(WidgetLinkCollection);
