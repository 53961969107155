import type {
  BinaryFilter,
  BinaryOperator,
  Filter,
  LogicalAndFilter,
  LogicalOrFilter,
  Query,
  QueryOrder,
  TQueryOrderArray,
  UnaryFilter,
} from "@cubejs-client/core";
import type {
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
} from "ag-grid-community";
import _ from "lodash";
import { convertStringToFilterType } from "../../../containers/reports/view/domain";
import { convertWlyDatePickerValueToMoment } from "../../../containers/reports/view/filters/date-filter/WlyDatePicker";
import {
  getObjectPresetFilters,
  type AvailableColumn,
} from "../../../containers/v2-demo/container/object/domain";
import type { IFilterEditorValue } from "../../../containers/v2-demo/container/record/component/widgets/related-lists/domain";
import { parseQueryBuilderSectionItemConfidProperty } from "../../../containers/workbench/workbench/viewer/object/tabs/query-builder/domain";
import type { IObject } from "../../../interfaces/object";
import { format } from "../../../utils/periodUtils";

export type ColumnSettings = {
  label?: string;
  displayImage?: boolean;
  initialSizeType?: "default" | "fill" | "fixedWidth";
  initialSizeWidth?: number;
};

export type ColumnsSettings = {
  [key: string]: ColumnSettings;
};

export type ISetTableQueryAction =
  | { action: "reset" }
  | { action: "setTableQuery"; tableQuery: TableQuery }
  | { action: "setFilters"; filters: Filter[] }
  | { action: "setPresetFilters"; presetIds: string[] }
  | { action: "addFilters"; filters: Filter[] }
  | { action: "toggleOrder"; order: [string, QueryOrder | undefined] }
  | { action: "setOrder"; order: TQueryOrderArray }
  | { action: "setGroups"; groups: ObjectTableGroup[] }
  | {
      action: "setQueryBuilderItems";
      queryBuilderItems: IQueryBuilderItemState | undefined;
    }
  | { action: "columnSizing"; columnSizing: ColumnSizingState }
  | { action: "columnPinning"; columnPinning: ColumnPinningState }
  | { action: "columnOrder"; columnOrder: ColumnOrderState }
  | {
      action: "columnVisibility";
      columnVisibility: {
        visibleColumnIds: string[];
      };
    }
  | {
      action: "columnVisibilityAndOrder";
      columnVisibility: {
        visibleColumnIds: string[];
      };
      columnOrder: ColumnOrderState;
    };

export type ObjectTableGroup = {
  groupedDimension: string;
  groupedField: string;
};

export type ObjectTableExpandedNode = {
  field: string;
  key: string | null;
  parentField: string | null | undefined;
  parentKey: string | null | undefined;
};

export type TableQuery = {
  filters: Filter[];
  queryBuilderItems?: IQueryBuilderItemState;
  order: TQueryOrderArray;
  groups: ObjectTableGroup[];
  presetFilters?: string[];
  columnSizing?: ColumnSizingState;
  columnPinning?: ColumnPinningState;
  columnOrder?: ColumnOrderState;
  columnVisibility?: {
    visibleColumnIds: string[];
  };
};

export type IQueryBuilderItemState = { [key: string]: string[] };

export type ISetTableUIStateAction =
  | {
      action: "setExpandedNodes";
      expandedNodes: ObjectTableExpandedNode[];
    }
  | { action: "setTableUIState"; tableUIState: TableUIState };

export type TableUIState = {
  expandedNodes: ObjectTableExpandedNode[];
};

export type BuildQueryOptions = {
  tableQuery: TableQuery;
  availableColumns: AvailableColumn[];
  object: IObject;
};

export const controlledPrefix = "controlled.";

export const parseAdditionalFilters = (additionalFilters: string): Filter[] => {
  try {
    if (!additionalFilters) {
      return [];
    }
    const t = JSON.parse(additionalFilters) as IFilterEditorValue;
    return [
      {
        [t.operator]: t.filters,
      },
    ] as Filter[];
  } catch (err) {
    console.warn(err);
    return [];
  }
};

// this method will remove all controlled filters and keep only filters with values
export const sanitizeFilterValues = (filters: Filter[]): Filter[] => {
  return filters
    .filter((f) => {
      if (
        (f as BinaryFilter | UnaryFilter).member?.startsWith(controlledPrefix)
      ) {
        return false;
      }
      return true;
    })
    .map<Filter>((f) => {
      if ((f as LogicalOrFilter).or) {
        return { or: sanitizeFilterValues((f as LogicalOrFilter).or) };
      } else if ((f as LogicalAndFilter).and) {
        return { and: sanitizeFilterValues((f as LogicalAndFilter).and) };
      } else {
        return f;
      }
    });
};

export const convertFilterValue = (
  value: string[],
  operator: BinaryOperator,
  column: AvailableColumn
): Filter[] => {
  if (column.type === "property") {
    // based on tileService.ts
    if (column.data.domain === "TIME") {
      if (!value?.[0]) {
        return [];
      }
      const v = convertWlyDatePickerValueToMoment(
        convertStringToFilterType(value[0], column.data.domain)
      );
      if (!v) {
        return [];
      }
      return [
        {
          member: column.data.key,
          operator: "inDateRange",
          values: [v[0].format(format), v[1].format(format)],
        },
      ];
    }
    if (column.data.domain === "BOOLEAN") {
      const v = convertStringToFilterType(value, "BOOLEAN");
      if (!v) {
        return [];
      }
      return [
        {
          member: column.data.key,
          operator: "equals",
          values: v,
        },
      ];
    }
  }
  if (value.length === 0) {
    return [];
  }
  return [
    {
      member: column.data.key,
      operator: operator ? operator : "equals",
      values: value,
    },
  ];
};

export const formatAdditionalFilters = (
  filters: Filter[],
  availableColumns: AvailableColumn[],
  storeValues: any
): Filter[] => {
  return filters.flatMap((f) => {
    if ((f as LogicalAndFilter)["and"]) {
      return {
        and: formatAdditionalFilters(f["and"], availableColumns, storeValues),
      };
    } else if ((f as LogicalOrFilter)["or"]) {
      return {
        or: formatAdditionalFilters(f["or"], availableColumns, storeValues),
      };
    } else {
      const isControlledFilter = ((f as BinaryFilter).member || "")?.startsWith(
        controlledPrefix
      );
      const foundControlledColumn = availableColumns.find(
        (ac) =>
          ac.data.key ===
          (f as BinaryFilter).member?.replaceAll(controlledPrefix, "")
      );

      if (isControlledFilter) {
        if (!foundControlledColumn) {
          return [];
        }
        const storeValue =
          storeValues?.[0]?.[foundControlledColumn.data.key] || [];
        return convertFilterValue(
          storeValue,
          (f as BinaryFilter).operator,
          foundControlledColumn
        );
      } else {
        if (!(f as BinaryFilter).values || !(f as BinaryFilter).values.length) {
          return [];
        }
        return [
          {
            member: (f as BinaryFilter).member,
            operator: (f as BinaryFilter).operator,
            values: (f as BinaryFilter).values,
          },
        ];
      }
    }
  });
};

export const createQueryBuilderFilters = (
  options: BuildQueryOptions
): Filter[] => {
  const allValues = options.tableQuery.queryBuilderItems || {};

  return (options.object.queryBuilderSections || []).flatMap((qbs) => {
    if (qbs.type === "OWN_PROPERTIES") {
      const allValuesIds = Object.keys(allValues);
      const hasOneValue = qbs.items.find((ai) => allValuesIds.includes(ai.id));
      if (hasOneValue) {
        return [
          {
            and: qbs.items.flatMap((item) => {
              const conf = parseQueryBuilderSectionItemConfidProperty(
                item.property
              );
              const storeValue = allValues[item.id];
              const key = conf?.[0]?.key;
              const foundColumn = options.availableColumns.find(
                (ac) => ac.data.key === key
              );
              if (foundColumn && storeValue) {
                return convertFilterValue(storeValue, "equals", foundColumn);
              }
              return [];
            }),
          },
        ];
      }
      return [];
    } else if (qbs.type === "FOREIGN_PROPERTIES") {
      const allValuesIds = Object.keys(allValues);
      const checkedItem = qbs.items.find((ai) => allValuesIds.includes(ai.id));
      if (checkedItem) {
        const additionalFilters = parseAdditionalFilters(
          checkedItem.additionalFilters
        );
        const storeValue = allValues[checkedItem.id];
        const controlledFilters = parseQueryBuilderSectionItemConfidProperty(
          checkedItem.property
        ).flatMap((p) => {
          const foundColumnd = options.availableColumns.find(
            (a) => a.data.key === p.key
          );
          if (foundColumnd) {
            return [foundColumnd];
          }
          return [];
        });
        const formattedAddionalFilters = formatAdditionalFilters(
          additionalFilters,
          controlledFilters,
          storeValue
        );

        return formattedAddionalFilters;
      }
      return [];
    } else {
      throw new Error(
        `Query builder section of type=${qbs.type} is not implemented`
      );
    }
  });
};

export const buildQuery = (options: BuildQueryOptions): Query => {
  const measures =
    options.availableColumns
      .filter(
        (c) =>
          c.type === "metric" &&
          options.tableQuery.columnVisibility?.visibleColumnIds.includes(
            c.data.key
          )
      )
      .map((m) => m.data.key) ?? [];
  const dimensions =
    options.availableColumns
      .filter(
        (c) =>
          c.type === "property" &&
          (options.tableQuery.columnVisibility?.visibleColumnIds.includes(
            c.data.key
          ) ||
            options.tableQuery.groups
              .map((g) => g.groupedDimension)
              .includes(c.data.key))
      )
      .map((m) => m.data.key) ?? [];

  const advancedFilters = options.tableQuery.filters;
  const presetFilters = (options.tableQuery.presetFilters ?? []).flatMap(
    (pf) => {
      const foundPreset = getObjectPresetFilters(options.object).find(
        (apf) => apf.id === pf
      )?.value;
      if (!foundPreset) return [];
      const filter = [
        { [foundPreset.operator]: foundPreset.filters },
      ] as Filter[];
      return filter;
    }
  );
  const queryBuilderFilters = createQueryBuilderFilters(options);

  const filters: Filter[] = [
    { and: [...advancedFilters, ...presetFilters, ...queryBuilderFilters] },
  ];
  const q: Query = {
    measures: _.uniq([`${options.object.table?.cubeName}.count`, ...measures]),
    dimensions: _.uniq([
      `${options.object.table?.cubeName}.label`,
      ...dimensions,
    ]),
    filters: filters,
    order: options.tableQuery.order,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    total: true,
    limit: 100,
    offset: 0,
  };
  return q;
};
