import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
  type FormInstance,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import { useState } from "react";
import type {
  ColumnSettings,
  ColumnsSettings,
} from "../../../../../../../components/ag-grid/object-table/domain";
import { grey } from "../../../../../../../utils/colorPalette";
import type { AvailableColumn } from "../../../../object/domain";

type ColumnSettingsEditorProps = {
  name: (string | number)[];
  availableColumns: AvailableColumn[];
  selectedColumns: string[];
  formInstance: FormInstance;
  currentColumnsSettings?: ColumnsSettings;
};
export const ColumnSettingsEditor = ({
  name,
  availableColumns,
  selectedColumns,
  formInstance,
  currentColumnsSettings,
}: ColumnSettingsEditorProps) => {
  return (
    <Flex vertical gap="small">
      {selectedColumns.map((column, index) => {
        const availableColumn = availableColumns.find(
          (c) => c.data.key === column
        );

        const columnName = availableColumn?.data.label ?? column;

        return (
          <ColumnSetting
            key={`${column}-${index}`}
            collapseKey={`${column}-${index}`}
            columnName={columnName}
            baseName={[...name, column]}
            formInstance={formInstance}
            currentColumnSettings={currentColumnsSettings?.[column]}
          />
        );
      })}
    </Flex>
  );
};

type SingleColumnSettingsEditorProps = {
  name: (string | number)[];
  label: string;
  formInstance: FormInstance;
};
export const SingleColumnSettingsEditor = ({
  name,
  label,
  formInstance,
}: SingleColumnSettingsEditorProps) => {
  return (
    <ColumnSetting
      collapseKey={`${label}-${name.join("-")}`}
      columnName={label}
      baseName={name}
      formInstance={formInstance}
    />
  );
};

type ColumnSettingProps = {
  collapseKey: string;
  baseName: (string | number)[];
  columnName: string;
  formInstance: FormInstance;
  currentColumnSettings?: ColumnSettings;
};
const ColumnSetting = ({
  collapseKey,
  columnName,
  baseName,
  formInstance,
  currentColumnSettings,
}: ColumnSettingProps) => {
  const [showForm, setShowForm] = useState(false);

  const initialSizeTypeFromForm = useWatch<ColumnSettings["initialSizeType"]>(
    [...baseName, "initialSizeType"],
    formInstance
  );

  const { initialSizeType: initialSizeTypeFromCurrent } =
    currentColumnSettings ?? {};

  const initialSizeType = initialSizeTypeFromForm ?? initialSizeTypeFromCurrent;

  return (
    <Collapse
      activeKey={showForm ? collapseKey : undefined}
      bordered={false}
      size="small"
      items={[
        {
          key: collapseKey,
          showArrow: false,
          style: {
            background: grey[3],
            borderRadius: 6,
          },
          forceRender: true,
          label: (
            <Flex gap="small" justify="space-between" align="center">
              <Typography.Text style={{ fontWeight: "initial" }}>
                {columnName}
              </Typography.Text>

              <Button
                size="small"
                type="text"
                icon={<EditOutlined />}
                onClick={() => setShowForm((prev) => !prev)}
              />
            </Flex>
          ),
          children: (
            <Space direction="vertical" style={{ width: "100%" }} size={12}>
              <div>
                <Typography.Text>Column name</Typography.Text>
                <br />
                <Form.Item name={[...baseName, "label"]} noStyle>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div>
                <Typography.Text>Display image</Typography.Text>
                <br />
                <Form.Item name={[...baseName, "displayImage"]} noStyle>
                  <Switch />
                </Form.Item>
              </div>

              <div>
                <Typography.Text>Initial size</Typography.Text>
                <br />
                <Flex vertical gap="small">
                  <Form.Item name={[...baseName, "initialSizeType"]} noStyle>
                    <Select
                      style={{ width: "100%" }}
                      options={[
                        { label: "Default", value: "default" },
                        { label: "Fill space", value: "fill" },
                        { label: "Fixed width", value: "fixedWidth" },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item name={[...baseName, "initialSizeWidth"]} noStyle>
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      suffix="px"
                      placeholder="size"
                      disabled={initialSizeType !== "fixedWidth"}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </Space>
          ),
        },
      ]}
      style={{
        background: grey[3],
        borderRadius: 6,
        overflow: "hidden",
      }}
    />
  );
};
